import { EditorAppSDK, EntryFieldAPI, FieldAPI } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';

export class ContentfulRepository {
  private sdk = useSDK<EditorAppSDK>();

  public getEntryField(property: string): EntryFieldAPI {
    return this.sdk.entry.fields[property];
  }

  public getEntryFieldForLocale(property: string, locale: string): FieldAPI {
    return this.sdk.entry.fields[property].getForLocale(locale);
  }

  public getEntryFieldValue<T>(property: string): T {
    return this.getEntryField(property).getValue();
  }

  public getEntryFieldValueForLocale<T>(property: string, locale: string): T {
    let result = this.getEntryFieldForLocale(property, locale).getValue();
    const fallbackLanguage = this.sdk.locales.fallbacks[locale];
    if (!result && fallbackLanguage) {
      result = this.getEntryFieldForLocale(property, fallbackLanguage).getValue();
    }
    return result;
  }

  public getEntryFieldValueForDefaultLocale<T>(property: string): T {
    return this.getEntryFieldForLocale(property, this.sdk.locales.default).getValue();
  }

  public setEntryFieldValue<T>(fieldName: string, value: T) {
    const storedValue = this.sdk.entry.fields[fieldName].getForLocale(this.sdk.locales.default).getValue();

    if (JSON.stringify(storedValue) !== JSON.stringify(value)) {
      this.sdk.entry.fields[fieldName].getForLocale(this.sdk.locales.default).setValue(value);
    }
  }

  public isFieldRequired(fieldName: string, locale: string): boolean {
    return !this.sdk.locales.optional[locale] && this.sdk.entry.fields[fieldName].getForLocale(locale).required;
  }
}
