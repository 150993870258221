import { FieldAppSDK } from '@contentful/app-sdk';
import { FieldWrapper } from '@contentful/default-field-editors';
import { FormControl } from '@contentful/f36-components';
import { MarkdownEditor } from '@contentful/field-editor-markdown';
import { SingleLineEditor } from '@contentful/field-editor-single-line';
import React, { useEffect } from 'react';
import { FIELD_HEADER } from '../helpers/Constants';
import 'codemirror/lib/codemirror.css';

export interface ContentfulFieldProps {
  sdk: FieldAppSDK;
  fieldName: string;
  fieldLabel: string;
  children?: JSX.Element;
  onValueChanged: (value: any) => void;
  availableLanguages: string[];
}

export const ContentfulField = (props: ContentfulFieldProps) => {
  const field = props.sdk.entry.fields[props.fieldName];

  const fieldAppSDK: FieldAppSDK = {
    ...props.sdk,
    field: field.getForLocale(props.sdk.locales.default)
  } as FieldAppSDK;

  useEffect(() => {
    const dettachableEvents: Function[] = [];

    props.sdk.locales.available.forEach((locale) => {
      if (field.locales.indexOf(locale) > -1) {
        dettachableEvents.push(
          props.sdk.entry.fields[props.fieldName].getForLocale(locale).onValueChanged(props.onValueChanged)
        );
      }
    });

    return () => {
      dettachableEvents.forEach((event) => event());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldExtensionForLocaleSDK = (locale: string): FieldAppSDK => {
    const sdk: FieldAppSDK = {
      ...props.sdk,
      field: field.getForLocale(locale)
    } as FieldAppSDK;
    return sdk;
  };

  const multiLanguageField = field.locales.length > 1;

  const emptyHeading = () => {
    return null;
  };

  const isFieldRequired = (locale: string): boolean => {
    return !props.sdk.locales.optional[locale] && field.getForLocale(locale).required;
  };

  const renderEditor = (locale: string = props.sdk.locales.default) => {
    return (
      <FormControl key={`header-control-${locale}`}>
        <FormControl.Label isRequired={isFieldRequired(locale)}>
          {`${props.fieldLabel} - ${props.sdk.locales.names[locale]}`}
        </FormControl.Label>
        {renderEditorContents(locale)}
      </FormControl>
    );
  };

  const renderEditorContents = (locale: string = props.sdk.locales.default) => {
    switch (field.type) {
      case 'Object':
      case 'Link':
        return props.children;
      case 'Symbol':
        return <SingleLineEditor field={field.getForLocale(locale)} locales={props.sdk.locales} />;
      case 'Text':
        return <MarkdownEditor sdk={getFieldExtensionForLocaleSDK(locale)} isInitiallyDisabled={false} />;
      default:
        return <>Unsupported Field</>;
    }
  };

  return (
    <FieldWrapper sdk={fieldAppSDK} name={FIELD_HEADER} renderHeading={emptyHeading}>
      {props.availableLanguages.indexOf(props.sdk.locales.default) !== -1 && renderEditor()}
      {multiLanguageField &&
        props.availableLanguages.filter((x) => x !== props.sdk.locales.default).map((locale) => renderEditor(locale))}
    </FieldWrapper>
  );
};
